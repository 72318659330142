import { useScreenSizeContext } from '@/pages/_app';
import {
  ClassifiedCreationOptions,
  FacetType,
  FormControlType,
  SearchPageAttribute,
  SearchPageField,
  SearchPageMakeModelVariantOption,
  SearchPageOptions,
} from '@/shared/lib-api';
import { Box, Grid, GridSize, ListItem } from '@mui/material';
import React, { use, useCallback, useEffect } from 'react';
import {
  FinalFormItemNumberInput,
  FinalFormItemTextAreaInput,
  FinalFormItemTextInput,
} from '../default-components/input/final-form/final-form-item-input';
import { FinalFormItemInputAutocomplete } from '../default-components/input/final-form/final-form-item-input-autocomplete';
import { FinalFormItemInputCheckBoxes } from '../default-components/input/final-form/final-form-item-input-checkbox';
import { FinalFormItemInputDatePicker } from '../default-components/input/final-form/final-form-item-input-datepicker';
import { FinalFormItemInputSelect } from '../default-components/input/final-form/final-form-item-input-select';
import DefaultListItemTextInput from '../default-components/input/redux/default-list-item-input';
import DefaultListItemInputAutocomplete from '../default-components/input/redux/default-list-item-input-autocomplete';
import DefaultListItemInputAutosuggestToFrom from '../default-components/input/redux/default-list-item-input-autosuggest-to-from';
import DefaultListItemInputCheckBoxes from '../default-components/input/redux/default-list-item-input-checkbox';
import DefaultListItemInputRadio from '../default-components/input/redux/default-list-item-input-radio';
import DefaultListItemInputSelect from '../default-components/input/redux/default-list-item-input-select';
import DefaultListItemInputToggleButton from '../default-components/input/redux/default-list-item-input-togglebutton';
import { SeoListItemInputAutocomplete } from '../default-components/input/redux/seo-inputs/seo-list-item-input-autocomplete';
import { SeoListItemInputAutosuggestToFrom } from '../default-components/input/redux/seo-inputs/seo-list-item-input-autosuggest-to-from';
import { SearchGroupField } from './search-group-field';
import { SearchMode, useSearchContext } from './search-section';

interface SearchFieldStyle {
  listTopPadding: string;
  listBottomPadding: string;
  gridItemInnerXs: boolean | GridSize;
  gridItemInnerSm: boolean | GridSize;
  topPadding: string;
  gridItemOuterXs: boolean | GridSize;
  gridItemOuterSm: boolean | GridSize;
  fieldWidth: string;
}

let headlinesToDisplayOnScreenMode: (keyof SearchPageOptions)[] = [
  'zipCode',
  'radius',
  'limitSearch',
];

let fieldsToDisableMultipleOnFrontpage: (keyof SearchPageOptions)[] = [
  'fuelTypes',
];

let fieldsToDisableMultipleOnSrp: (keyof SearchPageOptions)[] = [
  'gearBoxTypes',
];

let dropDownsToRadioButtons: (keyof SearchPageOptions)[] = ['category'];

let radioButtonsToToggles: (keyof SearchPageOptions)[] = ['profileType'];

let fieldsFill8ColsOnScreenMode: (keyof SearchPageOptions)[] = [];

let fieldsFill12ColsOnScreenMode: (keyof SearchPageOptions)[] = [
  'equipment',
  'colors',
  'category',
  'limitSearch',
];

let fieldsFill12ColsOnSeoSrpMode: (keyof SearchPageMakeModelVariantOption)[] = [
  'brand',
  'model',
];

let fieldsFill12ColsOnSyiMode: (keyof ClassifiedCreationOptions)[] = [
  'lastInspection',
  'regDate',
  'equipment',
  'description',
];

let fieldsFill3ColsOnSyiMode: (keyof ClassifiedCreationOptions)[] = [
  'category',
  'carTypes',
  'makeModelVariantOptions',
];

let fieldsToDisplayOnElHybrid: (keyof ClassifiedCreationOptions)[] = [
  'distance',
  'batteryCapacity',
  'energyConsumption',
];

let fieldsToDisplayOnNonEl: (keyof ClassifiedCreationOptions)[] = [
  'kmPerLiter',
  'engineSize',
];

let fieldsToDisplayOnTrailerHitch: (keyof ClassifiedCreationOptions)[] = [
  'maxTrailerWeight',
];

let fieldsToDisableOnDisplayBud: (keyof ClassifiedCreationOptions)[] = [
  'price',
  'pricePerMonth',
];

let fieldsWithFieldNextToThemOnTheLeftOnSyiMode: (keyof ClassifiedCreationOptions)[] =
  ['price', 'pricePerMonth', 'trailerHitch'];

let fieldsWithFieldNextToThemOnTheRightOnSyiMode: (keyof ClassifiedCreationOptions)[] =
  ['displayBud', 'maxTrailerWeight'];

let yearFrom: keyof SearchPageOptions = 'yearFrom';
let priceFrom: keyof SearchPageOptions = 'priceFrom';
let equipment: keyof SearchPageOptions = 'equipment';
let cartypes: keyof SearchPageOptions = 'carTypes';
let monthlyPriceFrom: keyof SearchPageOptions = 'pricePerMonthFrom';

const GenerateSearchField: React.FC<{
  mode: SearchMode;
  value: SearchPageField;
  index: number;
  array: SearchPageField[];
}> = ({ mode, value, index, array }) => {
  const { isMobile } = useScreenSizeContext();
  const {
    fuelTypeValue: fuelType,
    trailerHitchValue: trailerHitch,
    displayBudValue: displayBud,
  } = useSearchContext();

  const handleYearFieldOnFrontPage = useCallback(
    (value: SearchPageField) => {
      if (mode === 'frontpage' || mode === 'seo-srp') {
        if (value.formName === yearFrom.toString()) {
          value = {
            ...value,
            formNameSecondary: undefined,
            subHeader: 'FrontYear',
          };
        }
        value = {
          ...value,
          displayHeadline: false,
          placeholder: value.subHeader,
        };
      }
      return value;
    },
    [mode],
  );

  const handlePriceFieldOnFrontPage = useCallback(
    (value: SearchPageField) => {
      if (mode === 'frontpage') {
        if (value.formName === priceFrom.toString()) {
          value = {
            ...value,
            placeholder: 'FrontPrice',
          };
        }
        if (value.formName === monthlyPriceFrom.toString()) {
          value = {
            ...value,
            placeholder: 'FrontMonthlyPrice',
          };
        }
      }
      return value;
    },
    [mode],
  );

  const handleEquipment = useCallback((value: SearchPageField) => {
    if (value.formName === equipment.toString()) {
      let newAtts = value.attributes.map(p => {
        return {
          ...p,
          label: p.label === 'Træk' ? 'Anhængertræk' : p.label,
        };
      });
      newAtts.sort((a, b) => a.label.localeCompare(b.label));
      value = {
        ...value,
        attributes: newAtts,
      };
    }
    return value;
  }, []);

  const handleCarTypeRemoval = useCallback((value: SearchPageField) => {
    if (value.formName === cartypes.toString()) {
      let newAtts = value.attributes.filter(
        p =>
          p.value !== 'lastbil' &&
          p.value !== 'varevogn' &&
          p.value !== 'bus' &&
          p.value !== 'autocamper',
      );
      value = {
        ...value,
        attributes: newAtts,
      };
    }
    return value;
  }, []);

  const handleDisplayHeadline = useCallback(
    (value: SearchPageField) => {
      if (
        mode === 'screen' &&
        headlinesToDisplayOnScreenMode.some(
          key => key.toString() === value.formName,
        )
      ) {
        value = {
          ...value,
          displayHeadline: true,
        };
      }
      return value;
    },
    [mode],
  );

  const handleChangeOfFormType = useCallback(
    (value: SearchPageField) => {
      if (mode === 'screen') {
        if (
          dropDownsToRadioButtons.some(key => key.toString() === value.formName)
        ) {
          value = {
            ...value,
            formControlType: FormControlType.RadioButton,
          };
        } else if (
          radioButtonsToToggles.some(key => key.toString() === value.formName)
        ) {
          value = {
            ...value,
            formControlType: FormControlType.ToggleButton,
          };
        }
      }
      return value;
    },
    [mode],
  );

  const handleDisplayOfFieldsByTrailerHitch = useCallback(
    (value: SearchPageField) => {
      if (mode === 'syi') {
        if (
          trailerHitch !== undefined &&
          trailerHitch.value !== 'ja' &&
          fieldsToDisplayOnTrailerHitch.some(
            key => key.toString() === value.formName,
          )
        ) {
          value = {
            ...value,
            formControlType: FormControlType.Hidden,
          };
        }
      }
      return value;
    },
    [mode, trailerHitch],
  );

  const handleDisableFields = useCallback(
    (value: SearchPageField) => {
      if (mode === 'syi') {
        if (
          displayBud !== undefined &&
          displayBud.length !== 0 &&
          fieldsToDisableOnDisplayBud.some(
            key => key.toString() === value.formName,
          )
        ) {
          return true;
        }
      }

      return false;
    },
    [mode, displayBud],
  );

  const handleRemoveFields = useCallback(
    (value: SearchPageField) => {
      if (mode !== 'syi' && Array.isArray(fuelType)) {
        if (
          fuelType.length !== 0 &&
          fuelType.every(p => p.value == 'el') &&
          fieldsToDisplayOnNonEl.some(
            key =>
              key.toString() ===
              value.formName.replace('From', '').replace('To', ''),
          )
        ) {
          return true;
        } else if (
          fuelType.length !== 0 &&
          fuelType.every(p => p.value == 'diesel' || p.value == 'benzin') &&
          fieldsToDisplayOnElHybrid.some(
            key =>
              key.toString() ===
              value.formName.replace('From', '').replace('To', ''),
          )
        ) {
          return true;
        }
      }

      return false;
    },
    [mode, fuelType],
  );

  const handleDisableMultipleFieldsOnFrontPage = useCallback(
    (value: SearchPageField & { disableMulti?: boolean }) => {
      if (mode === 'frontpage') {
        if (
          fieldsToDisableMultipleOnFrontpage.some(
            key => key.toString() === value.formName,
          )
        ) {
          value = { ...value, disableMulti: true };
        }
      }
      return value;
    },
    [mode],
  );

  const handleDisableMultipleFieldsOnSrp = useCallback(
    (value: SearchPageField & { disableMulti?: boolean }) => {
      if (mode === 'sidebar' || mode === 'screen') {
        if (
          fieldsToDisableMultipleOnSrp.some(
            key => key.toString() === value.formName,
          )
        ) {
          value = { ...value, disableMulti: true };
        }
      }
      return value;
    },
    [mode],
  );

  const handleDisplayOfFieldsByFuelType = useCallback(
    (value: SearchPageField) => {
      if (mode === 'syi' && !Array.isArray(fuelType)) {
        if (fuelType === undefined || fuelType.value === '') {
          return value;
        }
        if (
          fuelType.value === 'el' &&
          fieldsToDisplayOnNonEl.some(key => key.toString() === value.formName)
        ) {
          value = {
            ...value,
            formControlType: FormControlType.Hidden,
          };
        } else if (
          fuelType.value !== 'el' &&
          fuelType.value !== 'hybrid' &&
          fieldsToDisplayOnElHybrid.some(
            key => key.toString() === value.formName,
          )
        ) {
          value = {
            ...value,
            formControlType: FormControlType.Hidden,
          };
        }
      }
      return value;
    },
    [mode, fuelType],
  );
  const getSearchFieldStyle = useCallback(
    (
      value: SearchPageField,
      index: number,
      array: SearchPageField[],
      isGroupedField: boolean,
    ): SearchFieldStyle => {
      let isLastItem = index === array.length - 1;
      let displayHalfWidth = value.displayHalfWidth;

      let result: SearchFieldStyle = {
        listTopPadding: value.displayHeadline ? '11px' : '6px',
        listBottomPadding: isLastItem ? '15px' : '8px',
        topPadding: 'initial',
        gridItemOuterSm: displayHalfWidth ? 6 : 12,
        gridItemOuterXs: 12,
        gridItemInnerSm: 12,
        gridItemInnerXs: 12,
        fieldWidth: '100%',
      };
      switch (mode) {
        case 'frontpage':
          result = {
            ...result,
            listTopPadding:
              value.facetType === FacetType.MultiSelectIntersection
                ? '0px'
                : '10px',
            listBottomPadding:
              value.facetType === FacetType.MultiSelectIntersection
                ? '0px'
                : '10px',
            topPadding: '0px!important',
            gridItemOuterSm: !isGroupedField && !isMobile ? 6 : 12,
          };
          break;
        case 'screen':
          result = {
            ...result,
            gridItemOuterSm:
              isGroupedField ||
              fieldsFill12ColsOnScreenMode.some(
                field => field === value.formName,
              )
                ? 12
                : fieldsFill8ColsOnScreenMode.some(
                    field => field === value.formName,
                  )
                ? 8
                : 4,
            listBottomPadding: '8px',
          };
          break;
        case 'seo-srp':
          result = {
            ...result,
            listTopPadding: '0px',
            listBottomPadding: '0px',
            gridItemOuterSm: isGroupedField
              ? 7.2
              : fieldsFill12ColsOnSeoSrpMode.some(field =>
                  value.formName.includes(field),
                )
              ? 6
              : 3.6,
          };
          break;
        case 'syi':
          let width: string = '204px';
          if (
            fieldsFill3ColsOnSyiMode.some(field =>
              value.formName.includes(field),
            )
          ) {
            width = '237px';
          } else if (
            fieldsFill12ColsOnSyiMode.some(field => field === value.formName)
          ) {
            width = '100%';
          }

          let gridItemOuterSm: boolean | GridSize = 12;
          if (
            fieldsWithFieldNextToThemOnTheLeftOnSyiMode.some(
              field => field === value.formName,
            ) ||
            fieldsWithFieldNextToThemOnTheRightOnSyiMode.some(
              field => field === value.formName,
            )
          ) {
            gridItemOuterSm = 'auto';
          }

          result = {
            ...result,
            gridItemInnerSm: 12,
            gridItemOuterSm: gridItemOuterSm,
            listTopPadding: '8px',
            listBottomPadding: '0px',
            fieldWidth: width,
          };
          break;
        default:
          break;
      }
      return result;
    },
    [mode, isMobile],
  );

  const result = useCallback(
    (value: SearchPageField, index: number, array: SearchPageField[]) => {
      let isGroupedField = value.groupedFields.length !== 0;

      value = handleYearFieldOnFrontPage(value);
      value = handleDisplayHeadline(value);
      value = handleCarTypeRemoval(value);
      value = handleChangeOfFormType(value);
      value = handleDisplayOfFieldsByTrailerHitch(value);
      value = handleDisplayOfFieldsByFuelType(value);
      value = handleDisableMultipleFieldsOnFrontPage(value);
      value = handleDisableMultipleFieldsOnSrp(value);
      value = handlePriceFieldOnFrontPage(value);
      value = handleEquipment(value);
      let disable = handleDisableFields(value);
      let remove = handleRemoveFields(value);

      let searchFieldStyle = getSearchFieldStyle(
        value,
        index,
        array,
        isGroupedField,
      );
      let selectOption = <></>;
      if (remove) {
        return;
      }

      if (isGroupedField) {
        selectOption = (
          <SearchGroupField value={value} index={index} mode={mode} />
        );
      } else {
        selectOption = (
          <ListItem
            style={{
              paddingTop: searchFieldStyle.listTopPadding,
              paddingBottom: searchFieldStyle.listBottomPadding,
            }}>
            <Grid container columnSpacing={mode === 'seo-srp' ? '0px' : '10px'}>
              <Grid
                key={index}
                item
                xs={searchFieldStyle.gridItemInnerXs}
                sm={searchFieldStyle.gridItemInnerSm}>
                <Box width={{ xs: '100%', sm: searchFieldStyle.fieldWidth }}>
                  {mode === 'syi'
                    ? AssignSYIInputType(value, disable)
                    : mode === 'seo-srp'
                    ? AssignSeoSRPInputType(value)
                    : AssignSRPInputType(value, disable, mode)}
                </Box>
              </Grid>
            </Grid>
          </ListItem>
        );
      }

      return value.formControlType === FormControlType.Hidden ? (
        <Box key={index} />
      ) : (
        <Grid
          paddingTop={searchFieldStyle.topPadding}
          item
          xs={searchFieldStyle.gridItemOuterXs}
          sm={searchFieldStyle.gridItemOuterSm}
          key={index}>
          {selectOption}
        </Grid>
      );
    },
    [
      mode,
      handleYearFieldOnFrontPage,
      handleRemoveFields,
      handleDisplayOfFieldsByFuelType,
      getSearchFieldStyle,
      handleDisplayHeadline,
      handlePriceFieldOnFrontPage,
      handleDisplayOfFieldsByTrailerHitch,
      handleChangeOfFormType,
      handleDisableFields,
      handleDisableMultipleFieldsOnFrontPage,
      handleDisableMultipleFieldsOnSrp,
      handleEquipment,
    ],
  );
  return result(value, index, array);
};

function AssignSYIInputType(value: SearchPageField, disable: boolean) {
  switch (value.formControlType) {
    case FormControlType.Number:
      return <FinalFormItemNumberInput {...value} disable={disable} />;
    case FormControlType.Text:
      return <FinalFormItemTextInput {...value} disable={disable} />;
    case FormControlType.Autosuggest:
      return <FinalFormItemInputAutocomplete {...value} />;
    case FormControlType.Checkbox:
      return <FinalFormItemInputCheckBoxes {...value} />;
    case FormControlType.DropDown:
      return <FinalFormItemInputSelect {...value} />;
    case FormControlType.YearMonthPicker:
      return <FinalFormItemInputDatePicker {...value} />;
    case FormControlType.TextArea:
      return <FinalFormItemTextAreaInput {...value} disable={disable} />;
    default:
      return;
  }
}

function AssignSRPInputType(
  value: SearchPageField & { disableMulti?: boolean },
  disable: boolean,
  mode: 'screen' | 'sidebar' | 'frontpage',
) {
  switch (value.formControlType) {
    case FormControlType.Text:
      return <DefaultListItemTextInput {...value} disable={disable} />;
    case FormControlType.Autosuggest:
      return <DefaultListItemInputAutocomplete {...value} />;
    case FormControlType.AutoSuggestToFrom:
      return <DefaultListItemInputAutosuggestToFrom {...value} />;
    case FormControlType.Checkbox:
      return <DefaultListItemInputCheckBoxes {...value} />;
    case FormControlType.DropDown:
      return <DefaultListItemInputSelect {...value} />;
    case FormControlType.ToggleButton:
      return <DefaultListItemInputToggleButton {...value} />;
    case FormControlType.RadioButton:
      return <DefaultListItemInputRadio {...value} mode={mode} />;
    default:
      return;
  }
}

function AssignSeoSRPInputType(value: SearchPageField) {
  switch (value.formControlType) {
    case FormControlType.Autosuggest:
      return <SeoListItemInputAutocomplete {...value} />;
    case FormControlType.AutoSuggestToFrom:
      return <SeoListItemInputAutosuggestToFrom {...value} />;
    default:
      return;
  }
}

export default React.memo(GenerateSearchField);
